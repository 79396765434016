import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '../components/Typography';
import LayoutBody from '../components/LayoutBody';
import theme from '../../styles/theme.js';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ServiceCard from '../components/ServiceCard';
import Code from '@material-ui/icons/Code';
import Cloud from '@material-ui/icons/CloudQueue';
import Lock from '@material-ui/icons/Lock';

// const backgroundImage = 'https://img1.wsimg.com/Sitecore/F/4/img-mrq-GD-guides-desktop-520.jpg';
const backgroundImage = '/services-hero2.jpg';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 10,
    marginBottom: 0,
    display: 'flex',
    color: theme.palette.secondary.main,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  greenAvatar: {
    margin: 30,
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    width: 80,
    height: 80,
  },
  iconSize: {
    fontSize: 48,
  },
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center bottom',
    height: 400,
  },
  negativeTopMargin: {
    marginTop: theme.spacing.unit * -15,
  },
  bottomPadding: {
    paddingBottom: theme.spacing.unit * 15,
  },
  topMargin: {
    marginTop: theme.spacing.unit * 10,
  },
  bgColor: {
    backgroundColor: theme.palette.background.paper,
  },
});

class CardRow extends React.Component {

  render() {
    const { classes } = this.props;

    const devList = [
        {
          title: 'Custom Web Sites',
        },
        {
          title: 'Node.js and React',
        },
        {
          title: 'Alexa Skills',
        },
        {
          title: 'Google Actions',
        },
        {
          title: 'Full-Stack Development',
        },
        // {
        //   title: 'Custom Integrations',
        // },
      ];

      const archList = [
        {
          title: 'AWS Architecture',
        },
        {
          title: 'Serverless Frameworks',
        },
        {
          title: 'Real-time Monitoring',
        },
        {
          title: 'Secure Environments',
        },
        {
          title: 'Web Applicaton Firewalls',
        },
        {
          title: 'Docker',
        },
        {
          title: 'Cloud Security Audits',
        },
        {
          title: 'Compliance',
        },
        {
          title: 'ISO Standards',
        },
      ];

      const devopsList = [
        {
          title: 'Deployment Pipelines',
        },
        {
          title: 'Build Processes',
        },
        {
          title: 'Automated Testing',
        },
        {
          title: 'Code Review Stages',
        },
        {
          title: 'Custom Templates for your Deployment',
        },
      ];

    return (
        <MuiThemeProvider theme={theme}>
          <section class={classes.bgColor}>
          <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
              <Grid item xs={12} className={classes.background}>
                  <Typography align="center" variant="h3" marked="center" className={classes.topMargin} component="h3">
                      Services We Offer
                  </Typography>
              </Grid>
          </Grid>
          <LayoutBody className={classNames(classes.negativeTopMargin, classes.bottomPadding)} component="section" width="large">
            <Grid container direction="row" justify="center" alignItems="top" spacing={16}>
                <Grid item xs={11} sm={8} md={4}>
                    <ServiceCard title="Development" copy="Utilize our developers to augment your existing development resources or we can complete an entire project. We will integrate with your existing deployment pipelines as needed." bulletedList={ devList } buttonText="learn more" path="/development"><Code className={classes.iconSize} /></ServiceCard>
                </Grid>
                <Grid item xs={11} sm={8} md={4} className="">
                    <ServiceCard title="Cloud Architecture" copy="Our AWS Certified Cloud Architects can help you configure your environment from scratch or advise on how you can improve an existing environment. Contact us to find out how we can help you." bulletedList={archList} buttonText="learn more" path="/cloud-architecture"><Cloud className={classes.iconSize} /></ServiceCard>
                </Grid>
                <Grid item xs={11} sm={8} md={4} className="">
                    <ServiceCard title="DevOps" copy="Step up your development game with our DevOps services. We can improve your processes with deployment pipelines and automated vulnerability testing." bulletedList={devopsList} buttonText="learn more" path="/devops"><Lock className={classes.iconSize} /></ServiceCard>
                </Grid>
            </Grid>
          </LayoutBody>
          </section>
    </MuiThemeProvider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CardRow);
