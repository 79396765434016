import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import TitleText from './modules/views/TitleText';
import AppAppBar from './modules/views/AppAppBar';
import CardRow from './modules/views/CardRow';
import Theme from './styles/theme.js';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

function Index() {
  return (
    <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <AppAppBar />
        <ProductHero />
        <TitleText title="Overview">
          {'Here at Chris Gooch Consulting, we are dedicated to making sure you recieve the highest level of expertise without the hastle of other providers. We taylor our services to your unique business needs to ensure you get exactly what you want.'}
          {'Our consultants are some of the best in the industry and can bring your business up to speed in the cloud. We have AWS Certified Architects that will design your cloud systems and our Developers can customize your experience to your needs. Take a look at the services we offer to get started.'}
        </TitleText>
        <CardRow />
        {/* <FullTitle title="Take a look at all this stuff we have" src="https://img1.wsimg.com/Sitecore/4/A/pattern-hp-banner-purple-600-v1.jpg"/> */}
        {/* <ProductHowItWorks /> */}
        {/* <ProductValues /> */}
        {/* <ProductCategories /> */}
        {/* <ProductValues /> */}
        {/* <ProductCTA /> */}
        <ProductSmokingHero />
        <AppFooter />
    </MuiThemeProvider>
  );
}

export default withRoot(Index);
