import React from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '../components/Typography';
import LayoutBody from '../components/LayoutBody';
import theme from '../../styles/theme.js';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 10,
    marginBottom: 0,
    display: 'flex',
  },
  bottomMargin: {
    marginBottom: theme.spacing.unit * 10,
  },
});

class TitleText extends React.Component {

  render() {
    const { classes, title, children } = this.props;

    return (
    <MuiThemeProvider theme={theme}>
        <LayoutBody className={classNames(classes.root, classes.bottomMargin)} component="section" width="large">
            <Grid container direction="row" justify="center" alignItems="center"spacing={16}>
                <Grid item xs={10} className="">
                    <Typography align="center" variant="h3" marked="center" className={classes.title} component="h3" gutterBottom>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={10} className="">
                    {children.map(children => (
                        <Typography variant="body2" color="inherit" className="" paragraph>
                            {children}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        </LayoutBody>
    </MuiThemeProvider>
    );
  }
}

TitleText.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
  };

export default withStyles(styles, { withTheme: true })(TitleText);
