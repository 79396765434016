import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '../components/Typography';
import theme from '../../styles/theme.js';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/KeyboardArrowRight';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 10,
    marginBottom: 0,
    display: 'flex',
    color: theme.palette.secondary.main,
  },
  card: {
    // maxWidth: 345,
  },
  greenAvatar: {
    margin: 30,
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    width: 80,
    height: 80,
  },
  iconSize: {
    fontSize: 48,
  },
  negativeTopMargin: {
    marginTop: theme.spacing.unit * -10,
  },
  bottomMargin: {
    marginBottom: theme.spacing.unit * 10,
  },
  topMargin: {
    marginTop: theme.spacing.unit * 10,
  },
});

class ServiceCard extends React.Component {

  render() {
    // const { classes } = this.props;

    const {
        classes,
        title,
        copy,
        bulletedList,
        children,
      } = this.props;

    // const devList = [
    //     {
    //       title: 'Snorkeling',
    //     },
    //     {
    //       title: 'Massage',
    //     },
    //     {
    //       title: 'Hiking',
    //     },
    //   ];

    return (
        <MuiThemeProvider theme={theme}>
            <Card className={classes.card} raised>
                <Grid container justify="center" alignItems="center">
                    <Avatar className={classes.greenAvatar}>
                        {children}
                    </Avatar>
                </Grid>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography component="p" paragraph>
                        {copy}
                    </Typography>
                    <List dense="true">
                    {bulletedList.map(bulletedList => (
                    <ListItem>
                        <ListItemIcon>
                        <ListIcon color='secondary'/>
                        </ListItemIcon>
                        <ListItemText
                        primary={bulletedList.title}
                        />
                    </ListItem>
                    ))}
                    </List>
                </CardContent>
                {/* <CardActions>
                    <Grid container justify="flex-end" alignItems="center">
                        <Button size="small" color="secondary" href={path}>
                          {buttonText}
                        </Button>
                    </Grid>
                </CardActions> */}
            </Card>
    </MuiThemeProvider>
    );
  }
}

  ServiceCard.propTypes = {
    children: PropTypes.node,
    copy: PropTypes.string,
    title: PropTypes.string,
    bulletedList: PropTypes.object,
    buttonText: PropTypes.string,
    path: PropTypes.string,
  };

  ServiceCard.defaultProps = {
    buttonText: 'learn more',
  };

export default withStyles(styles, { withTheme: true })(ServiceCard);
