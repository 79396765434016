import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Home from './Home'
import page404 from './404'
import Contact from './Contact'

// ReactDOM.render(<Index />, document.getElementById('root'));

ReactDOM.render((
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={Home}/>
            {/* <Route exact path='/development' component={Development}/> */}
            {/* <Route exact path='/cloud-architecture' component={CloudArchitecture}/> */}
            {/* <Route exact path='/devops' component={Devops}/> */}
            <Route path='/contact/' component={Contact}/>
            <Route component={page404} />
        </Switch>
    </BrowserRouter>
  ), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();