import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import TitleText from './modules/views/TitleText';
import AppAppBar from './modules/views/AppAppBar';
import Theme from './styles/theme.js';
import { MuiThemeProvider } from '@material-ui/core/styles';

function Index() {
  return (
    <MuiThemeProvider theme={Theme}>
        <AppAppBar />
        <TitleText title="Oh no, something went wrong.">
          {'I\'m afraid that page cannot be found'}
          {'Please try something else.'}
        </TitleText>
        <AppFooter />
    </MuiThemeProvider>
  );
}

export default withRoot(Index);
