import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { withStyles } from '@material-ui/core/styles';
// import theme from '../../styles/theme.js';
// import { MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';
// import TextField from '../components/TextField';

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.grey[300],
    marginTop: theme.spacing.unit * 10,
    paddingTop: theme.spacing.unit * 4,
  },
  layoutBody: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  list: {
    margin: 0,
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
  topMargin: {
    marginTop: theme.spacing.unit * 15,
  },
});


function AppFooter(props) {
  const { classes } = props;

  return (
    <Typography component="footer" className={classes.root} >
      <LayoutBody className={classes.layoutBody} width="large">
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justify="flex-end"
              classNames={classes.topMargin}
              spacing={16}
            >
              <Grid item>© 2019 ChrisGoochConsulting</Grid>
            </Grid>
          </Grid>
        </Grid>
      </LayoutBody>
    </Typography>
  );
}

AppFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
)(AppFooter);
