import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import ContactHero from './modules/views/ContactHero';
import ContactForm from './modules/views/ContactForm';
import Theme from './styles/theme.js';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

function Index() {
  return (
    <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <AppAppBar />
        <ContactHero />
        {/* <TitleText title="Overview">
          {'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dapibus velit aliquam leo gravida malesuada. Morbi a ante ac turpis vehicula vestibulum. Vestibulum metus arcu, lobortis at nisi dictum, faucibus fermentum magna. Donec sollicitudin aliquet urna, a lobortis magna. Duis bibendum libero nec nunc dapibus tempor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer at maximus dolor. Aliquam convallis pretium eros, at convallis purus pellentesque eu. Etiam luctus interdum convallis. Integer lorem diam, consectetur rhoncus tempor nec, blandit id turpis. Praesent euismod eu nunc vel tincidunt. Morbi non neque purus.'}
          {'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dapibus velit aliquam leo gravida malesuada. Morbi a ante ac turpis vehicula vestibulum. Vestibulum metus arcu, lobortis at nisi dictum, faucibus fermentum magna. Donec sollicitudin aliquet urna, a lobortis magna. Duis bibendum libero nec nunc dapibus tempor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer at maximus dolor. Aliquam convallis pretium eros, at convallis purus pellentesque eu. Etiam luctus interdum convallis. Integer lorem diam, consectetur rhoncus tempor nec, blandit id turpis. Praesent euismod eu nunc vel tincidunt. Morbi non neque purus.'}
        </TitleText> */}
        {/* <CardRow /> */}
        {/* <FullTitle title="What's on your mind?" src="https://img1.wsimg.com/Sitecore/4/A/pattern-hp-banner-purple-600-v1.jpg"/> */}
        {/* <ProductHowItWorks /> */}
        {/* <ProductValues /> */}
        {/* <ProductCategories /> */}
        {/* <ProductValues /> */}
        <ContactForm />
        {/* <ProductSmokingHero /> */}
        <AppFooter />
    </MuiThemeProvider>
  );
}

export default withRoot(Index);
