import React from 'react';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Typography from '../components/Typography';
import LayoutBody from '../components/LayoutBody';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Send from '@material-ui/icons/Send';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import theme from '../../styles/theme.js';

const request = require('request');
// const recaptchaRef = React.createRef();

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 10,
    marginBottom: 0,
    display: 'flex',
  },
  bottomMargin: {
    marginBottom: theme.spacing.unit * 10,
  },
  textField: {
      width: '100%',
      marginBottom: theme.spacing.unit * 3,
  },
  maw600: {
      maxWidth: 600,
  },
  button: {
      marginTop: theme.spacing.unit * 2,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

const topicList = [
    {
        value: '',
        label: '',
    },
    {
      value: 'development',
      label: 'Development',
    },
    {
      value: 'architecture',
      label: 'Cloud Architecture',
    },
    {
      value: 'security',
      label: 'Security',
    },
    {
      value: 'something-else',
      label: 'Something Else',
    },
  ];

class ContactForm extends React.Component {
    state = {
        open: false,
        snackMessage: 'Thank you for your interest. We will contact you shortly.',
        name: '',
        email: '',
        topic: '',
        message: '',
        recaptcha_response: '',
      };
    
    //   onChange = value => {
    //     console.log("Captcha value:", value);
    //   }

      handleSubmit = event => {
        event.preventDefault();

        // const recaptchaValue = recaptchaRef.current.getValue();
        // this.props.onSubmit(recaptchaValue);

        // alert('A name was submitted: ' + this.state.name);
        let data = {
            name: this.state.name,
            email: this.state.email,
            topic: this.state.topic,
            message: this.state.message,
            // recaptcha_response: this.state.recaptcha_response,
            recaptcha_response: this.state.recaptcha_response,

        };

        // Configure the request
        let options = {
            url: 'https://cnkogtyewb.execute-api.us-west-2.amazonaws.com/TEST/contact',
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            // headers: {
            //     "Access-Control-Allow-Origin": "chrisgoochconsulting.com"
            // },
            form: JSON.stringify(data),
        }

        const handleSubmitSuccess = () => {
            this.setState({
              open: true,
              snackMessage: 'Thank you for your interest. We will contact you shortly.',
              name: '',
              email: '',
              topic: '',
              message: '',
            });
          };
        
        const handleSubmitError = () => {
            this.setState({
              open: true,
              snackMessage: 'An error occurred with your submission.',
            });
          };

        // Start the request
        request(options, function (error, response, body) {
            if (!error && response.statusCode === 200) {
                handleSubmitSuccess();
            } else {
                handleSubmitError();
            }
        })

      };

      handleClose = () => {
        this.setState({
          open: false,
        });
      };
    
      handleChange = name => event => {
        this.setState({ [name]: event.target.value });
      };

  render() {
    const { classes } = this.props;

    return (
    <MuiThemeProvider theme={theme}>
    <GoogleReCaptchaProvider reCaptchaKey="6Len-YEUAAAAAM0EKRVHBTpErm8q5U4-tbNvpsRQ">
    <GoogleReCaptcha onVerify={token => this.setState({ recaptcha_response: token })} />
        <LayoutBody className={classNames(classes.root, classes.bottomMargin)} component="section" width="medium">
            <Grid container direction="row" justify="center" alignItems="center"spacing={0}>
                <Grid item xs={10} sm={8} className={classes.maw600}>
                    <Typography variant="body2" color="inherit" paragraph>
                        Please let us know what kind of services you're interested in and we will respond as quickly as possible.
                    </Typography>
                </Grid>
                <Grid item xs={10} sm={8} className={classes.maw600}>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                        className={classes.container} autoComplete="off"
                    >
                        <TextValidator
                            id="name-input"
                            label="Your Name"
                            // className={classes.textField}
                            value={this.state.name}
                            validators={['required']}
                            errorMessages={['Name is required']}
                            onChange={this.handleChange('name')}
                            margin="normal"
                            // variant="outlined"
                            fullWidth
                        />
                        <TextValidator
                            id="email-input"
                            label="Your Email"
                            className={classes.textField}
                            value={this.state.email}
                            validators={['required', 'isEmail']}
                            errorMessages={['Email is required', 'Must be a valid email address']}
                            onChange={this.handleChange('email')}
                            margin="normal"
                            // variant="outlined"
                            fullWidth
                        />
                        <FormControl required>
                        <InputLabel htmlFor="topic-input">Topic</InputLabel>
                        <Select
                            id="topic-input"
                            select
                            native
                            // label="Topic"
                            // className={classes.textField}
                            value={this.state.topic}
                            // validators={['required']}
                            // errorMessages={['Topic is required']}
                            onChange={this.handleChange('topic')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            inputProps={{
                                id: 'topic-input',
                                }}
                            // helperText="Please select your topic"
                            margin="normal"
                            // variant="outlined"
                            fullWidth
                            >
                                {/* <option value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option> */}
                            {topicList.map(option => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                            ))}
                        </Select>
                        {/* <FormHelperText>Topic is required</FormHelperText> */}
                        </FormControl>
                        <TextValidator
                            id="message-input"
                            label="Your Message"
                            multiline
                            rowsMax="6"
                            value={this.state.message}
                            validators={['required']}
                            errorMessages={['Message is required']}
                            onChange={this.handleChange('message')}
                            // className={classes.textField}
                            margin="normal"
                            // variant="outlined"
                            fullWidth
                        />
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            size="large"
                            className={classes.button}
                        >
                            Send
                            <Send className={classes.rightIcon} />
                        </Button>
                        {/* <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Len-YEUAAAAAM0EKRVHBTpErm8q5U4-tbNvpsRQ"
                            onChange={this.onChange}
                        /> */}
                        {/* <TextValidator
                            id="recaptchaResponse"
                            value={this.state.recaptcha_response}
                            onChange={this.handleChange('recaptcha_response')}
                        /> */}
                        {/* <input type="input" name="recaptcha_response" id="recaptchaResponse" /> */}

                    </ValidatorForm>
                </Grid>
            </Grid>
            <Snackbar
                open={this.state.open}
                onClose={this.handleClose}
                message={this.state.snackMessage}
            />
        </LayoutBody>
        </GoogleReCaptchaProvider>
    </MuiThemeProvider>
    );
  }
}

ContactForm.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
  };

export default withStyles(styles, { withTheme: true })(ContactForm);
